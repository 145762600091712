import { FormControl } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Spacer, Stack, Text } from "@chakra-ui/layout";
import "./styles.css";
import { IconButton, Spinner, useToast } from "@chakra-ui/react";
import { getSender, getSenderFull } from "../config/ChatLogics";
import { useEffect, useState } from "react";
import axios from "axios";
import { ArrowBackIcon } from "@chakra-ui/icons";
import ProfileModal from "./miscellaneous/ProfileModal";
import ScrollableChat from "./ScrollableChat";
import Lottie from "react-lottie";
import animationData from "../animations/typing.json";
import parser from "socket.io-msgpack-parser";
import io from "socket.io-client";
import UpdateGroupChatModal from "./miscellaneous/UpdateGroupChatModal";
import { ChatState } from "../Context/ChatProvider";
import UserAvatar from "./userAvatar/UserAvatar";
import GroupAvatar from "./userAvatar/GroupAvatar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const ENDPOINT = "https://general-semantic.onrender.com"; // "https://General Semantic.herokuapp.com"; -> After deployment
var socket, selectedChatCompare;

const SingleChat = ({ fetchAgain, setFetchAgain }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);

  const toast = useToast();

  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { selectedChat, setSelectedChat, user, notification, setNotification, onlineUsers, setOnlineUsers } =
    ChatState();

  const fetchMessages = async () => {
    if (!selectedChat) return;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${ user.token }`,
          "Content-type": "application/json",

          "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
          "X-Frame-Options": "deny",
          "X-XSS-Protection": "1; mode=block",
          "Expect-CT": "max-age=86400, enforce",
          "X-Content-Type-Options": "nosniff",
          "Referrer-Policy": "origin-when-cross-origin",
          "Cache-Control": "no-store",
          "Feature-Policy": "microphone 'none'; camera 'none'",


        },
      };

      setLoading(true);

      const { data } = await axios.get(
        `https://general-semantic.onrender.com/api/message/${ selectedChat._id }`,
        config
      );
      setMessages(data);
      setLoading(false);
      socket.emit("join chat", selectedChat._id);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to Load the Messages",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const sendMessage = async (event) => {
    if (event.key === "Enter" && newMessage) {
      socket.emit("stop typing", selectedChat._id);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${ user.token }`,
            "Content-type": "application/json",
            "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
            "Content-Security-Policy": "default-src 'self'",
            "X-Frame-Options": "deny",
            "X-XSS-Protection": "1; mode=block",
            "Expect-CT": "max-age=86400, enforce",
            "X-Content-Type-Options": "nosniff",
            "Referrer-Policy": "origin-when-cross-origin",
            "Cache-Control": "no-store",
            "Feature-Policy": "microphone 'none'; camera 'none'",
          },
        };
        setNewMessage("");
        const { data } = await axios.post(
          "https://general-semantic.onrender.com/api/message",
          {
            content: newMessage,
            chatId: selectedChat,
          },
          config
        );
        socket.emit("new message", data);
        setMessages([...messages, data]);
      } catch (error) {
        toast({
          title: "Error Occured!",
          description: "Failed to send the Message",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    }
  };

  useEffect(() => {
    socket = io(ENDPOINT, { parser, 'transports': ['websocket', 'polling'] });
    socket.emit("setup", user);
    socket.on("connected", () => setSocketConnected(true));
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
    socket.on("onlineUsers", (res) => setOnlineUsers(res));

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    fetchMessages();

    selectedChatCompare = selectedChat;
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    socket.on("message recieved", (newMessageRecieved) => {
      if (
        !selectedChatCompare || // if chat is not selected or doesn't match current chat
        selectedChatCompare._id !== newMessageRecieved.chat._id
      ) {
        if (!notification.includes(newMessageRecieved)) {
          setNotification([newMessageRecieved, ...notification]);
          setFetchAgain(!fetchAgain);
        }
      } else {
        setMessages([...messages, newMessageRecieved]);
      }
    });
  });

  const typingHandler = (e) => {
    setNewMessage(e.target.value);

    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", selectedChat._id);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };
  const handleBack = () => {
    setSelectedChat("")
    // history.push("/chats");

  }

  return (
    <>
      {selectedChat ? (
        <>
          <Text
            fontSize={{ base: "28px", md: "30px" }}
            pb={3}
            px={2}
            w="100%"
            fontFamily="Work sans"
            fontWeight="bold"
            d="flex"
          // justifyContent={{ base: "space-between" }}
          // alignItems="center"
          >
            <IconButton
              d={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
              onClick={() => { handleBack() }}
              colorScheme="blue"
            />
            &nbsp;&nbsp;
            {messages &&
              (!selectedChat.isGroupChat ? (
                <>
                  <UserAvatar user={getSenderFull(user, selectedChat.users)} onlineUser={onlineUsers} size={'md'} />
                  <Stack ml='10px' spacing={0}>
                    <Text fontSize="sm" fontWeight='thin'>Direct Messages</Text>
                    <Text fontSize="lg">{getSender(user, selectedChat.users)}</Text>
                  </Stack>
                  <Spacer />
                  <ProfileModal
                    user={getSenderFull(user, selectedChat.users)}
                  />

                </>
              ) : (
                <>
                  <GroupAvatar size={'md'} maxObj={4} />
                  <Stack ml='10px' spacing={0}>
                    <Text fontSize="sm" fontWeight='thin'>Chatroom Messages</Text>
                    <Text fontSize="lg">#&nbsp;{selectedChat.chatName.toUpperCase()}</Text>
                  </Stack>
                  <Spacer />
                  <UpdateGroupChatModal
                    fetchMessages={fetchMessages}
                    fetchAgain={fetchAgain}
                    setFetchAgain={setFetchAgain}
                  />
                </>
              ))}
          </Text>
          <Box
            d="flex"
            flexDir="column"
            justifyContent="flex-end"
            p={3}
            bg="#282D3E"
            w="100%"
            h="100%"
            borderRadius="lg"
            overflowY="hidden"
          >
            {loading ? (
              <Spinner
                size="xl"
                w={20}
                h={20}
                alignSelf="center"
                margin="auto"
              />
            ) : (
              <div className="messages">
                <ScrollableChat messages={messages} />
              </div>
            )}

            <FormControl
              onKeyDown={sendMessage}
              id="first-name"
              isRequired
              mt={3}
            >
              {istyping ? (
                <div>
                  <Lottie
                    options={defaultOptions}
                    // height={50}
                    width={70}
                    style={{
                      marginBottom: 15, marginLeft: 0,
                      // background: "#242933"
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              <Input
                variant="filled"
                bg="#242933"
                placeholder="Enter a message.."
                value={newMessage}
                onChange={typingHandler}
                _hover={{
                  bg: '#242933'
                }}
              />
            </FormControl>
          </Box>
        </>
      ) : (
        // to get socket.io on same page
        <Box d="flex" alignItems="center" justifyContent="center" h="100%">
          <Text fontSize="lg" pb={3} fontFamily="Work sans">
            Click on a user to start chatting
          </Text>
        </Box>
      )}
    </>
  );
};

export default SingleChat;
