import { Avatar, AvatarBadge } from '@chakra-ui/react';
import React from 'react';

export default function UserAvatar({ user, size, onlineUser }) {

    var badgeColor;

    if (onlineUser.indexOf(user._id) !== -1) { badgeColor = 'green.500' }
    else { badgeColor = 'tomato' }
    return (
        <Avatar
            size={size}
            src={user.pic}
            name={user.name}
        >
            <AvatarBadge boxSize='1em' bg={badgeColor} />
        </Avatar>
    );
}
