import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { VStack } from "@chakra-ui/layout";
import { useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Reaptcha from "reaptcha";

const Login = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  // const captchaRef = useRef(null)

  const history = useHistory();

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value)
  }
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value)
  }
  const submitHandler = async (event) => {
    event.preventDefault();

    setLoading(true);
    if (!email || !password) {
      toast({
        title: "Please Fill all the Feilds",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
      return;
    }

    // console.log(email, password);
    try {
      const config = {
        headers: {
          "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
          "X-Frame-Options": "deny",
          "X-XSS-Protection": "1; mode=block",
          "Expect-CT": "max-age=86400, enforce",
          "X-Content-Type-Options": "nosniff",
          "Referrer-Policy": "origin-when-cross-origin",
          "Cache-Control": "no-store",
          "Feature-Policy": "microphone 'none'; camera 'none'",


        },
      };
      // const token = captchaRef.current.getValue();
      // captchaRef.current.reset();

      const { data } = await axios.post(
        `https://general-semantic.onrender.com/api/user/login`,
        { email, password },
        config
      );
      // const { bot } = await axios.post(
      //   `https://www.google.com/recaptcha/api/siteverify?secret=6Lc__zQlAAAAALXMk5FLgA1xnz5Igev6sb9IyynT&response=${ token }`
      // );
      // console.log(bot)
      // console.log(JSON.stringify(data));
      toast({
        title: "Login Successful",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
      setLoading(false);

      history.push("/chats");
      window.location.reload();
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: error?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      setLoading(false);
    }
  };

  return (
    <VStack spacing="10px">
      <FormControl id="email1" isRequired>
        {/* <FormLabel>Email Address</FormLabel> */}
        <Input borderColor='gray.600'

          value={email}
          type="email"
          placeholder="Enter Your Email Address"
          onChange={handleEmailChange}
        />
      </FormControl>
      <FormControl id="password1" isRequired>
        {/* <FormLabel>Password</FormLabel> */}
        <InputGroup size="md">
          <Input borderColor='gray.600'

            value={password}
            onChange={handlePasswordChange}
            type={show ? "text" : "password"}
            placeholder="Enter password"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick} colorScheme='purple'>
              {show ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Reaptcha
        sitekey="6Lc__zQlAAAAAMV-L-Vq8gZqBndJP-fRlgTKGtc3"
        // ref={captchaRef}
        onVerify={() => setVerified(true)}
      />
      <Button

        width="100%"
        style={{ marginTop: 15 }}
        onClick={submitHandler}
        isLoading={loading}
        isDisabled={!verified}
        bgGradient='linear(to-l, #7928CA, #FF0080)'
        _hover={{ bgGradient: 'linear(to-l, #FF0080, #7928CA)' }}
        // _hover={{ bg: '#ebedf0' }}
        _active={{
          bgGradient: 'linear(to-r, teal.500, green.500)',
          transform: 'scale(0.98)',
          borderColor: '#bec3c9',
        }}
      >
        Login
      </Button>
      {/* <Button
        variant="solid"
        colorScheme="red"
        width="100%"
        onClick={() => {
          setEmail("guest@example.com");
          setPassword("123456");
        }}
      >
        Get Guest User Credentials
      </Button> */}
    </VStack>
  );
};

export default Login;
