import {
  Box,
  Container,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Login from "../components/Authentication/Login";
import Signup from "../components/Authentication/Signup";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function Homepage() {
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));

    if (user) {
      const decodedJwt = parseJwt(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem("userInfo");
        history.push("/");
      }
      history.push("/chats");

    }
  }, [history]);

  return (
    <Container maxW="md" centerContent>
      <Box
        d="flex"
        justifyContent="center"
        p={3}
        bg="#242933"
        w="100%"
        m="40px 0 15px 0"
        borderRadius="xl"
        // borderWidth="1px"
        color="#FFFFFF"
      >
        <Text
          fontSize="3xl"
          bgGradient={[
            'linear(to-tr, teal.300, yellow.400)',
            'linear(to-t, blue.200, teal.500)',
            'linear(to-b, orange.100, purple.300)',
          ]}
          bgClip='text'
          fontWeight='extrabold'

        >
          General Semantic
        </Text>
      </Box>
      <Box bg="#242933" w="100%" p={4} borderRadius="xl" color="#FFFFFF">
        <Tabs isFitted variant="soft-rounded" colorScheme="purple">
          <TabList mb="1em">
            <Tab>Login</Tab>
            <Tab>Sign Up</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Login />
            </TabPanel>
            <TabPanel>
              <Signup />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
}

export default Homepage;
